import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  "Functional & NonFunctional": "632a2a4ddc4d",
  "Gereksinim ve Sorunlar": "632a2a4ddc4d",
  "Teknolojik Gelişimi": "632a2a4ddc4d",
  "Generic, Dynamic": "632a2a4ddc4d",
  "Ekip İletişimi": "632a2a4ddc4d",

  "Yönetimsel Başarısızlıklar": "3b157ae9ca1",
  "Süreçsel Problemler": "3b157ae9ca1",
  "Mimari Problemler": "3b157ae9ca1",
  "Geliştirici Ekip": "3b157ae9ca1",
  "Teknolojik Gelişim ve Baskı": "3b157ae9ca1",

  Planlama: "ad8a5c5b9684",
  Analiz: "ad8a5c5b9684",
  Tasarım: "ad8a5c5b9684",
  Gerçekleştirme: "ad8a5c5b9684",
  Bakım: "ad8a5c5b9684",

  "Code&Fix": "ad8a5c5b9684",
  Waterfall: "ad8a5c5b9684",
  "V-Model": "ad8a5c5b9684",
  Evolutionary: "ad8a5c5b9684",
  Prototyping: "ad8a5c5b9684",
  Spiral: "ad8a5c5b9684",
  Incremental: "ad8a5c5b9684",
  UML: "ad8a5c5b9684",
  "Agile(XP,Kanban, Scrum)": "ad8a5c5b9684",

  "İş Paketleri": "b33d9c02b717",
  "Alt Kırılımlar": "b33d9c02b717",
  "Kaç Kişi, Yetenekler ve Roller": "b33d9c02b717",
  "Project Planning": "b33d9c02b717",

  "Yazılım Geliştirme Yöntemim": "9a0aac25f471",

  "İş ve Hayat Dengesi": "e9f3604f7ba9",
  "Work/Life Balance": "e9f3604f7ba9",

  "Ustalık-Çıraklık": "221c21ce6478",

  "FDD(Feature Driven Development)": "5bcdf6223c5a",
  "TDD(Test Driven Development)": "5bcdf6223c5a",
  "BDD(Behaviour Driven Development)": "5bcdf6223c5a",
  "ATDD(Acceptance Test Driven Development)": "5bcdf6223c5a",
  "MDD(Model Driven Development)": "5bcdf6223c5a",
  "DDD(Domain Driven Development)": "5bcdf6223c5a",
}

const stories = [
  {
    title: "Yazılım Mimarının 5 Yanlışı",
    postId: "632a2a4ddc4d",
  },
  {
    title: "Kod Kalitesini Neler Kötü Etkiler",
    postId: "3b157ae9ca1",
  },
  {
    title: "Neden Sürece İhtiyaç Var",
    postId: "ad8a5c5b9684",
  },
  {
    title: "Proje Planı Nasıl Hazırlanır",
    postId: "b33d9c02b717",
  },
  {
    title: "Yazılım Geliştirme Yöntemim",
    postId: "9a0aac25f471",
  },
  {
    title: "İş ve Hayat Dengesi",
    postId: "e9f3604f7ba9",
  },
  {
    title: "Ustalık-Çıraklık İlişkisi",
    postId: "221c21ce6478",
  },
  {
    title: "{x} Driven Development",
    postId: "5bcdf6223c5a",
  },
  {
    title: "Startup'da Mühenislik",
    postId: "7288afd5bf24",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Mimari Deneyim",
  path: "mimari-deneyim",
}

const ArchExperiencePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ArchExperiencePage
